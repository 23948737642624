import { IMsalContext } from "@azure/msal-react";
import { protectedResources } from "./authConfig";
import { QueryClient } from "react-query";
import { BaseFolder, BaseFolderQueryKey, BaseFolderQueryURL, getAADToAwsTokenQueryKey } from "./types";
import {AWSError, STS} from 'aws-sdk'
import { AwsClient } from 'aws4fetch'
import { PromiseResult } from 'aws-sdk/lib/request';

export class FetchValidationErrors extends Error  {
    public title: string;
    public status: number;
    public type?: string;
    public traceId?: string;
    public detail?: string;
    public instance?: string;
    public errors?: any;

    constructor(init:any) {
        super('fetch error');
        this.name = 'FetchValidationErrors';
        this.title = init.title;
        this.status = init.status
        if (init.type)
            this.type = init.type
        if (init.traceId)
            this.traceId = init.traceId
        if (init.detail)
            this.detail = init.detail
        if (init.instance)
            this.instance = init.instance 
        if (init.errors)
            this.errors = init.errors        
    }

    getErrorsToDisplay() : Array<{key:string, value:string}>{
        const retour : Array<{key:string, value:string}> = [];
        if (this.errors)
            Object.entries(this.errors).map((e)=> {
                const v = e[1] as string[];
                retour.push({key:e[0], value:v.join(",") });
                return retour;
             })
        return retour;
    }

}

export class ProblemDocument {

}

export function callApiBackend<Result>(context: IMsalContext, apiRelative: string, method:string = "GET", options?: { body?:any, doNotCheckResult?: boolean,abortSignal?: AbortSignal} ): Promise<Result|undefined> {
    return context.instance.acquireTokenSilent({
        scopes: protectedResources.apiFLASH.scopes,
        account: context.accounts[0]
    }).then(response => {
        return callApiWithToken<Result>(response.accessToken, protectedResources.apiFLASH.endpoint + apiRelative, method, options?.body, options?.doNotCheckResult, options?.abortSignal);
    })    
}

async function addAccesTokenToAwsCredentials(queryCLient: QueryClient, aadAccessToken: string,  roleArn:string, roleSessionName: string): Promise<STS.Credentials> {
  let aADToAwsTokenStaleTime = 1*60*60 * 1000; // 1 heure
  // cache the AWS token (try to get from cache, if not, call API and put in cache)
  const {Credentials} = await (queryCLient.getQueryData(getAADToAwsTokenQueryKey()) as Promise<PromiseResult<STS.AssumeRoleWithWebIdentityResponse, AWSError>> ?? 
    queryCLient.fetchQuery(getAADToAwsTokenQueryKey(),  () => {
        // not in cache, get a new token
        const sts = new STS({ region: "eu-central-1" });
        const params = {
          RoleArn: roleArn ,
          RoleSessionName: roleSessionName ,
          WebIdentityToken: aadAccessToken,
          DurationSeconds: aADToAwsTokenStaleTime/1000,// 1 hour
        };
        return sts.assumeRoleWithWebIdentity(params).promise(); 
      }
      ,{staleTime:aADToAwsTokenStaleTime}));
    if (!Credentials  )
      throw new Error("cannot get Aws Credential");
    return Credentials;
}
 async function callAwsAPIBackend<Result>(Credentials: STS.Credentials, url: string, method:string = "GET", options?: { body?:any, abortSignal?: AbortSignal }): Promise<Result|undefined> {
  const aws = new AwsClient({
    accessKeyId: Credentials.AccessKeyId,
    secretAccessKey: Credentials.SecretAccessKey,
    sessionToken: Credentials.SessionToken,
    service: 'execute-api',
    region: "eu-central-1",
    retries: 0
  });
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const request = new Request(url, {
    method: method,
    body: JSON.stringify(options?.body),
    signal: options?.abortSignal
  });
  const response = await aws.fetch(request, requestOptions);
  if (!response.ok)
    throw new Error("cannot get token");
  return await response.json() as Result;
}

export async function callAwsApiBackend<Result>(context: IMsalContext,queryCLient: QueryClient, roleArn:string, roleSessionName: string, url: string, method:string = "GET", options?: { body?:any, abortSignal?: AbortSignal }): Promise<Result|undefined> {
  // first get the token for the API  (msal cache automatically the token between calls)
  const aadtoken = await context.instance.acquireTokenSilent({
    scopes: protectedResources.apiAwsFLASH.scopes,
    account: context.accounts[0]
  });
  
  // exchange the AAD token for an AWS token
  const Credentials = await addAccesTokenToAwsCredentials(queryCLient, aadtoken.accessToken, roleArn, roleSessionName);
  // finally call the API
  return await callAwsAPIBackend(Credentials, url, method, options);
}




export function callApiWithToken<Result>(accessToken: string, apiEndpoint: string, method:string = "GET", body?:any, doNotCheckResult?: boolean, abortSignal?: AbortSignal): Promise<Result|undefined> {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", 'application/json;charset=UTF-8');
    headers.append("x-apif-apikey", protectedResources.apiFLASH.Apimkey);

    let options: RequestInit = {
        method: method,
        headers: headers,
        mode: 'cors' as RequestMode,
        body: JSON.stringify(body),
        signal: abortSignal
    };


    return fetch(apiEndpoint, options)
        .then(async(response) => {
            if (method!=="GET" && response.status===400){
                const json = await response.json();
                throw new FetchValidationErrors( json   )
            }
            if (response.status === 403){
                throw new Error('unauthorized');
            }
            if (!response.ok) {

                throw new Error(response.statusText)
            }
            if (doNotCheckResult!= null && doNotCheckResult)
                return;
            if (response.status === 204)
                return;
            return response.json() as Promise<Result>
        })
}


export function callApiWithTokenNoResponse<Result>(accessToken: string, apiEndpoint: string, method:string = "GET", body?:Result): Promise<void> {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", 'application/json;charset=UTF-8');

    let options: RequestInit = {
        method: method,
        headers: headers,
        mode: 'cors' as RequestMode,
        body: JSON.stringify(body)
    };


    return fetch(apiEndpoint, options)
        .then(response => {
            if (!response.ok) {
                throw new Error(response.statusText)
            }
            return;
        })
}


export function callApiGetBaseFolder<Result>( queryClient: QueryClient,  cloudStorageId: string, context: IMsalContext): Promise<Result> {
    // read https://tkdodo.eu/blog/react-query-meets-react-router to undeerstand the ?? 
    const queryKey = BaseFolderQueryKey(cloudStorageId);
    const url = BaseFolderQueryURL(cloudStorageId);
    return queryClient.getQueryData(queryKey) ?? 
           queryClient.fetchQuery(queryKey,  () =>  callApiBackend<Result>(context, url, "GET")
        //    , {
        // staleTime: 1000 * 60 * 60 * 1, // 1 hour of cache
        // } 
    )    
}

export function getBaseFolderFromCache( queryClient: QueryClient,  cloudStorageId: string): BaseFolder | undefined {
    // read https://tkdodo.eu/blog/react-query-meets-react-router to undeerstand the ?? 
    const queryKey = BaseFolderQueryKey(cloudStorageId);
    return queryClient.getQueryData(queryKey);
}

